import React from "react";
import { TypeTester } from "typor";
import Label from "../../components/Label";
// import { convertToRaw } from "draft-js";
import { useTranslation } from "react-i18next";

const IntroSample3Label = (props) => {
  const { t } = useTranslation();

  return <th className="StyleListing__item-label">{t(props.children)}</th>;
};

class IntroSample3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: props.children,
      fontSize: null,
      maxFontSize: null,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    // this.handleOnChangeTester = this.handleOnChangeTester.bind(this);

    this.styles = [
      {
        label: "Regular",
        style: {},
      },
      {
        label: "Italic",
        style: { fontStyle: "italic" },
      },
      {
        label: "Bold",
        style: { fontWeight: "bold" },
        scale: true,
        scaleOnLineBreak: false,
        scaleOnEditAndLineBreak: false,
        onChangeFontSize: (newFontSize, details) => {
          if (newFontSize) {
            let nextState = {
              fontSize: newFontSize,
            };

            if (this.state.fontSize === null) {
              nextState["maxFontSize"] = newFontSize;
            }

            this.setState(nextState);
          }
        },
      },
      {
        label: "Bold Italic",
        style: { fontWeight: "bold", fontStyle: "italic" },
      },
    ];
  }

  handleOnChange(e) {
    if (e && e.target && typeof e.target.value !== "undefined") {
      this.setState({
        text: e.target.value,
      });
    }
  }

  render() {
    return (
      <div className="IntroSample3">
        <div className="IntroSample3__controls">
          <Label text="Text">
            <input
              className="input"
              type="text"
              value={this.state.text}
              onChange={this.handleOnChange}
            />
          </Label>
        </div>
        <div
          aria-hidden
          style={{
            fontSize: this.state.fontSize
              ? `${this.state.fontSize}px`
              : undefined,
          }}
        >
          <table className="StyleListing">
            <tbody>
              {this.styles.map(({ label, ...itemProps }, index) => {
                return (
                  <tr
                    key={`IntroSample3_${index}`}
                    className="StyleListing__item"
                  >
                    <IntroSample3Label>{label}</IntroSample3Label>
                    <td className="StyleListing__item-dynaimc">
                      <TypeTester
                        readOnly
                        value={this.state.text}
                        {...itemProps}
                        maxFontSize={this.state.maxFontSize || undefined}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default IntroSample3;
