import React from "react";
import ControlledTypeTesterCustomized from "../components/ControlledTypeTesterCustomized";
import { useTranslation } from "react-i18next";

const AndronTester = ({ controls, ...testerProps }) => {
  const { t } = useTranslation();

  // Translate labels
  let translatedControls = controls.map((controlProp) => {
    let ctrlObj = Object.assign({}, controlProp);

    for (let ctrlKey in ctrlObj) {
      if (ctrlObj.hasOwnProperty(ctrlKey)) {
        let ctrl = ctrlObj[ctrlKey]

        if (ctrl.options && Array.isArray(ctrl.options)) {
          ctrl.options = ctrl.options.map((opt) => {
            if (opt.label) {
              opt.label = t(opt.label);
            }

            return opt
          });
        }
    
        if (ctrl.label) {
          ctrl.label = t(ctrl.label);
        }
      }
    }

    return ctrlObj
  });

  return (
    <ControlledTypeTesterCustomized
      {...testerProps}
      controls={translatedControls}
      onChangeBeforeSetState={(e, newState, currentState) => {
        const cS = currentState;
        const target = e.target;

        // This is a way to define logic specific to Andron typeface, for specific controls,
        // without needing to override the entire ControlledTypeTesterCustomized.
        // Intending to move ControlledTypeTesterCustomized back into Typor’s ControlledTypeTester.

        // This is the `font-family`, not the label
        const family = [
          "Andron Mega Corpus Regular",
          "Andron Mega Corpus Italic",
          "Andron Mega Corpus SC Regular",
          "Andron Mega Corpus SC Italic",
        ];

        if (target.type === "checkbox") {
          switch (target.name) {
            case "smcp":
              if (target.checked) {
                newState["fontFamily"] = family[2];

                if (cS.italic) {
                  newState["fontFamily"] = family[3];
                }
              } else {
                newState["fontFamily"] = family[0];

                if (cS.italic) {
                  newState["fontFamily"] = family[1];
                }
              }
              break;
            case "italic":
              if (target.checked) {
                newState["fontFamily"] = family[1];

                if (cS.smcp) {
                  newState["fontFamily"] = family[3];
                }
              } else {
                newState["fontFamily"] = family[0];

                if (cS.smcp) {
                  newState["fontFamily"] = family[2];
                }
              }
              break;
            default:
              break;
          }
        } else if (
          typeof target.tagName !== "undefined" &&
          target.tagName.toLowerCase() === "select"
        ) {
          if (target.value.includes(" SC")) {
            newState["smcp"] = true;
          } else {
            newState["smcp"] = false;
          }

          if (target.value.includes(" Italic")) {
            newState["italic"] = true;
          } else {
            newState["italic"] = false;
          }
        }

        return newState;
      }}
    />
  );
};

AndronTester.defaultProps = {
  value: "Type in your text here…",
  textAlignment: "left",
  scale: false,
  scaleOnEditAndLineBreak: false,
  caret: true,
  controls: [
    {
      fontFamily: {
        label: "Typeface",
        type: "select",
        defaultValue: "Andron Mega Corpus Regular",
        options: [
          {
            label: "Andron Regular",
            value: "Andron Mega Corpus Regular",
          },
          {
            label: "Andron Italic",
            value: "Andron Mega Corpus Italic",
          },
          {
            label: "Andron Bold",
            value: "Andron Mega Corpus Bold",
          },
          {
            label: "Andron Bold Italic",
            value: "Andron Mega Corpus Bold Italic",
          },
          {
            label: "Andron Small Caps Regular",
            value: "Andron Mega Corpus SC Regular",
          },
          {
            label: "Andron Small Caps Italic",
            value: "Andron Mega Corpus SC Italic",
          },
        ],
      },
      fontSize: {
        label: "Font Size",
        type: "range",
        defaultValue: 36,
        min: 4,
        max: 400,
        units: "px",
      },
      lineHeight: {
        label: "Line Height",
        type: "range",
        defaultValue: 1.4,
        min: 1,
        max: 2,
        step: 0.1,
      },
      smcp: {
        type: "checkbox",
        label: "Small Caps",
      },
      italic: {
        type: "checkbox",
        label: "Italics",
      },
    },
  ],
};

export default AndronTester;
