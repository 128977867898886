import React from "react";

const TranslationToggle = (props) => {
  const currentPath =
    typeof props.currentPath !== "undefined" && props.currentPath
      ? props.currentPath
      : "";

  return (
    <ul className="TranslationToggle">
      {props.translations.map((lang, index) => {
        const newPath = `${lang.href}${currentPath}`.replace('//', '/');

        return (
          <li
            key={`lang_${lang.labelShort}_${index}`}
            className={`TranslationToggle__item ${
              props.currentLocale && props.currentLocale === lang.locale
                ? "TranslationToggle__item--active"
                : ""
            }`}
          >
            <a className="TranslationToggle__link" href={newPath}>
              {lang.labelShort}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default TranslationToggle;
