import React from "react";
import PrimaryNav from "./PrimaryNav";
import Wrapper from "./Wrapper";

const Footer = (props) => {
  const d = new Date();
  const year = d.getFullYear();
  const author = props.site.siteMetadata.author || "Andreas Stötzner";

  return (
    <div className="Footer">
      <PrimaryNav
        sticky={false}
        isPrimaryNav={false}
        currentPath={props.currentPath}
        currentLocale={props.pageContext.locale}
      />
      <small className="Footer__statement">
        <Wrapper>
          <span class="Footer__statement-text">
            Copyright © {year} {author}
          </span>
          <span class="Footer__statement-ornament"> ❧ </span>
          <span class="Footer__statement-text">
            Website development by{" "}
            <a href="https://kennethormandy.com" target="_blank">
              Kenneth Ormandy
            </a>
          </span>
        </Wrapper>
      </small>
    </div>
  );
};

export default Footer;
