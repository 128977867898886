import React from "react";
import PropTypes from "prop-types";

// Ours
import CharacterGridItem from './CharacterGridItem'

// prettier-ignore
const specialCharacters = [
  { label: 'Alchemical', description: '', items: '℈ ℥ 🜃 🜅 🜈 🜋 🜏 🜑 🜓 🜖 🜛 🜝 🜟 🜡 🜤 🜦 🜩 🜮 🜶 🜸 🜹 🜾 🝇 🝍 🝒 🝛'.split(' '), },
  { label: 'Astrological', description: '', items: '☊ ☍ ☉ ☾ ♂ ☿ ♃ ♀ ♄ ♅ ♆ ⚳ ⚴ ⚹ ⚻ ♈ ♉ ♊ ♋ ♌ ♍ ♎ ♏ ♐ ♑ ♒ ♓'.split(' '), },
  { label: 'Arrows', description: '', items: '↑ → ↓ ↔ ↕ ↗ ↯ ⇀ ⇁ ⇨ ⇧ ⤉ ⤓ ⤢'.split(' '), },
  { label: 'Emoticons', description: '', items: '☺ ☹ 😁 😃 😉 😍 😎 😲'.split(' '), },
  { label: 'Enclosed alphanumerics', description: '', items: '① ② ③ ④ ⑤ Ⓐ Ⓑ Ⓒ Ⓓ Ⓔ ⓐ ⓑ ⓒ ⓓ ⓔ'.split(' '), },
  { label: 'Geometric', description: '', items: '□ ▪ △ ▷ ◆ ◈ ○ ◍ ◐ ◔ ◵'.split(' '), },
  { label: 'Mathematical', description: '', items: 'µ ∀ ℇ ∅ ∉ ∊ ∋ ∏ ∑ √ ∫ ∞ ∭ ∷ ≅ ∻ ≠ ⊕ ⋶ ⌀'.split(' '), },
  { label: 'Miscellaneous', description: '', items: '☞ ♣ ♠ ♡ ♢ ⚂ ⚅ ⚆ ⚉ ⌘ ☏ ✗ ☑ ☤ ⚒ ⚓ ⚔ ⚕ ⚖ ⚙ ⚡ ⚭ ✂ ✉ ✎ ⌂'.split(' '), },
  { label: 'Monetary and measurement', description: '', items: '₡ ₤ ₥ ₮ ℳ ₦ ₫ ₰ ℓ ℔      '.split(' '), },
  { label: 'Musical', description: '', items: '♭ ♮ ♯ 𝄆 𝄞 𝄐 𝄡 𝄵 𝅝 𝅘𝅥𝅮 𝅘𝅥𝅱 𝆷 𝆹 𝆹𝅥𝅮 𝆺𝅥𝅮 𝇇 𝇉 𝇎'.split(' '), },
  { label: 'Ornamental', description: '', items: '✶ ❀ ❥ ❧ ❄'.split(' '), },
  { label: 'Prosodic', description: '', items: '         '.split(' '), },
  { label: 'Public signage', description: '', items: '♿ 🚲 ⚠ ❬ 🍴 💱 🕛 🎁 🚮 🚹 🚼 🚿'.split(' '), },
  { label: 'Religious', description: '', items: '✡ ✠ ✝ ☧ ⳥ ☩  '.split(' '), },
  { label: 'Transport signage', description: '', items: '✈ 🚄 🚈 🚋 🚗 🚢 🚗 🚌 🛄'.split(' '), },
  { label: 'Typographical', description: '', items: '※ ⁂ ¶ ⁌ ⅌ ⁊ ‰ ‡ ϗ ⅍ ♾ ℞ ℣ ℗ № ℅ ⅓ ⅗ ⅜ ) ] ⁆ ⟧ ⟩ }'.split(' '), },
]

class CharacterGrid extends React.Component {
  constructor() {
    super();

    this.state = {
      selectedGroupIndex: 0,
      selectedGlyphIndex: 0,
      gridFontSize: 24,
    };
  }

  render() {
    const props = this.props;
    const state = this.state;
    const selectedGlyph =
      specialCharacters[state.selectedGroupIndex].items[
      state.selectedGlyphIndex
      ];

    return (
      <div>
        <div
          className="CharacterGrid"
          style={{
            "--character-grid-font-size": `${state.gridFontSize}px`,
          }}
        >
          <div className="CharacterGrid__output-container">
            <div className="CharacterGrid__output-box">
              <div className="CharacterGrid__output-glyph">{selectedGlyph}</div>
            </div>
          </div>
          <div className="CharacterGrid__items-container">
            <label>
              Font Size
              <input
                type="range"
                min={9}
                max={48}
                value={state.gridFontSize}
                onChange={(e) =>
                  this.setState({
                    gridFontSize: parseInt(e.target.value, 10),
                  })
                }
              />
            </label>
            <div>
              {specialCharacters.map((obj, index) => {
                const keyPrefix = `${props.keyPrefix}_${obj.label}_`
                return (
                  <CharacterGridItem
                    key={`${keyPrefix}${index}`}
                    keyPrefix={keyPrefix}
                    {...obj}
                    {...state}
                    groupIndex={index}
                    onClick={(e) => {
                      if (e.target && typeof e.target.dataset !== "undefined") {
                        const dataset = e.target.dataset;
                        this.setState({
                          selectedGroupIndex: parseInt(dataset.groupIndex, 10),
                          selectedGlyphIndex: parseInt(dataset.glyphIndex, 10),
                        });
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CharacterGrid.defaultProps = {
  keyPrefix: 'char_grid'
}

CharacterGrid.propTypes = {
  keyPrefix: PropTypes.string,
};

export default CharacterGrid;
