import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import { LocalizedLink } from "gatsby-theme-i18n";

import TranslationToggle from "./TranslationToggle";
import Button from "../components/Button";

const getIsActive = (currentPath, slug) => {
  return typeof currentPath === "undefined"
    ? false
    : slug === currentPath.replace("de/", "");
};

const PrimaryNavList = (props) => {
  const { t } = useTranslation();
  const ctaAsButton = props.ctaAsButton;

  if (!props.nodes || !props.nodes.length) {
    return null;
  }

  return (
    <ul className="PrimaryNav__list">
      {props.nodes.map(({ frontmatter, ...node }, index) => {
        if (!frontmatter) {
          return null;
        }

        if (
          typeof frontmatter.hideInPrimaryNav !== "undefined" &&
          props.isPrimaryNav === true &&
          frontmatter.hideInPrimaryNav === true
        ) {
          return null;
        }

        const label = frontmatter.titleShort || frontmatter.title;

        // NOTE Wouldn’t support an additional language, but that shouldn’t be an issue for us
        const active = getIsActive(props.currentPath, frontmatter.slug);

        return (
          <li className="PrimaryNav__item" key={`menu_${node.id || index}`}>
            <LocalizedLink
              className={`PrimaryNav__link ${
                active ? "PrimaryNav__link--active" : ""
              }`}
              to={frontmatter.slug}
            >
              {t(label).replace(" ", " ")}
            </LocalizedLink>
          </li>
        );
      })}
      <li className="PrimaryNav__item">
        {ctaAsButton ? (
          <div className={`PrimaryNav__link PrimaryNav__link--cta`}>
            <Button is={LocalizedLink} to="/contact/" outline>
              {t("Contact")}
            </Button>
          </div>
        ) : (
          <LocalizedLink
            className={`PrimaryNav__link ${
              getIsActive(props.currentPath, "/contact/")
                ? "PrimaryNav__link--active"
                : ""
            }`}
            to="/contact/"
          >
            {t("Contact")}
          </LocalizedLink>
        )}
      </li>
    </ul>
  );
};

PrimaryNavList.defaultProps = {
  isPrimaryNav: true,
  ctaAsButton: false,
};

const PrimaryNav = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            author
          }
        }

        allMdx(
          sort: { fields: fileAbsolutePath, order: ASC }
          filter: {
            frontmatter: { slug: { ne: null } }
            fields: { locale: { eq: "en" } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              titleShort
              slug
              hideInPrimaryNav
            }
          }
        }
      }
    `
  );

  return (
    <div className={`PrimaryNav ${props.sticky ? "PrimaryNav--sticky" : ""} ${props.isPrimaryNav ? '' : 'PrimaryNav--footer'}`}>
      <nav className="PrimaryNav__container">
        <div className="PrimaryNav__container-inner">
          <div className="PrimaryNav__item PrimaryNav__item--name">
            <LocalizedLink to="/" className="PrimaryNav__link">
              {data.site.siteMetadata.title}
            </LocalizedLink>
          </div>
          <input
            id="nav-toggle"
            type="checkbox"
            defaultChecked={!props.isPrimaryNav}
            className={`PrimaryNav__toggle-button ${
              props.isPrimaryNav ? "" : "hide"
            }`}
          />
          {props.isPrimaryNav ? (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label
              for="nav-toggle"
              aria-hidden
              className="PrimaryNav__toggle-icon"
            >
              Ⲷ
            </label>
          ) : null}
          <div className="PrimaryNav__toggle-container">
            <PrimaryNavList
              isPrimaryNav={props.isPrimaryNav}
              currentPath={props.currentPath}
              nodes={data.allMdx.nodes}
            />
          </div>
          <div className="PrimaryNav__controls">
            <TranslationToggle {...props} />
          </div>
        </div>
      </nav>
    </div>
  );
};

PrimaryNav.defaultProps = {
  sticky: false,
  isPrimaryNav: true,
  translations: [
    { label: "English", labelShort: "En", href: "/", locale: "en" },
    { label: "Deutsch", labelShort: "De", href: "/de/", locale: "de" },
  ],
};

export default PrimaryNav;
