import React, { Component } from "react";
import PropTypes from "prop-types";
import { ControlledTypeTester, TypeTester } from "typor";

// This is a hack until those other components are actually accessible
const DefaultInput = ControlledTypeTester.defaultProps.components.Input;
const DefaultSlider = (props) => (
  <div className="ControlledTypeTester__slider">
    <ControlledTypeTester.defaultProps.components.Slider {...props} />
  </div>
);

const DefaultSelect = ControlledTypeTester.defaultProps.components.Select;
const DefaultOption = ControlledTypeTester.defaultProps.components.Option;
const DefaultLabel = ({ className, ...remainingProps }) => {
  return (
    <div className={className}>
      <ControlledTypeTester.defaultProps.components.Label {...remainingProps} />
    </div>
  );
};
const DefaultContainer = ControlledTypeTester.defaultProps.components.Container;

const defaultComponents = {
  Label: DefaultLabel,
  // This is probably getting replaced with the new ToolbarContainer and ToolbarRow
  // Toolbar: DefaultToolbar,
  Container: DefaultContainer,
  Select: DefaultSelect,
  Option: DefaultOption,
  Input: DefaultInput,
  Slider: DefaultSlider,
};

// This doesn’t exist in ControlledTypeTester yet, we only added support for rows in this
// file now, and that needs to be ported back in
const ToolbarContainer = (props) => (
  <div
    {...props}
    // style={{ width: "100%", borderBottom: "1px solid", marginBottom: "1rem" }}
    className="ToolbarContainer"
  >
    {props.children}
    {/* <style>{`@media (min-width: 750px) { .ToolbarContainer { display: flex; } }`}</style> */}
  </div>
);

const ToolbarRow = (props) => <div {...props}>{props.children}</div>;

const supportedProperties = {
  fontFamily: {
    label: "Typeface",
    type: "select",
    defaultValue: "Work Sans",
    options: [
      "Work Sans",
      { label: "Avenir Next", value: "AvenirNext-Bold" },
      "Georgia",
    ],
  },
  fontSize: {
    label: "Font Size",
    type: "range",
    defaultValue: 50,
    min: TypeTester.defaultProps.minFontSize,
    max: TypeTester.defaultProps.maxFontSize,
    units: "px",
  },
  lineHeight: {
    label: "Line Height",
    type: "range",
    defaultValue: 1.2,
    min: 1,
    max: 2,
    step: 0.1,
  },
  letterSpacing: {
    label: "Tracking",
    type: "range",
    defaultValue: 0,
    min: -0.2,
    max: 0.2,
    step: 0.01,
    units: "em", // ems work, but mess with scale sizing
  },
};

class ControlledTypeTesterCustomized extends Component {
  constructor(props) {
    super(props);

    let initialState = {
      scale: props.scale,
      fontSize: 16,
      lineHeight: props.defaultLineHeight,
    };

    // Makes it possible to override a single component
    this.components = Object.assign({}, defaultComponents, props.components);

    // TODO Support array of config objects, for control groups?
    // TODO Object.assign doesn’t seem to deep merge object, might want
    //      to iterate over control objects (ex. fontFamily) and run
    //      Object.assign on each of those instead. Then, you can keep
    //      the default config, ex. font family is still a dropdown, even
    //      when I provide my own options.

    // let inputRows = props.inputRows.map((inputRow, j) => {
    //   return this.formatInputRow(inputRow)
    // })

    this.controlConfig = props.controls;

    // Create initial state from control defaults
    this.controlConfig.forEach((controlRow, i) => {
      if (!Array.isArray(controlRow) && typeof controlRow === "object") {
        Object.keys(controlRow).forEach((key, j) => {
          let obj = controlRow[key];

          initialState[key] =
            obj.type === "checkbox" ? obj.defaultChecked : obj.defaultValue;
        });
      }
    });

    this.keyPrefix = this.getKeyPrefix(props);

    this.state = initialState;
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(e) {
    if (!e || typeof e.target === "undefined") {
      console.error(
        "No `event.target` provided. If you are using a custom component, you might have to “wrap” its `onChange` function to provide the `event.target.value` and `event.target.name`."
      );
      return;
    }

    const target = e.target;
    const value =
      typeof target.type !== "undefined" && target.type === "checkbox"
        ? target.checked
        : target.value;
    const name = target.name;

    let newState = {};
    newState[name] = value;

    // TODO Same for auto-lineHeight
    if (name === "fontSize") {
      newState["scale"] = false;
    }

    if (typeof this.props.onChangeBeforeSetState === "function") {
      newState = this.props.onChangeBeforeSetState(e, newState, this.state);
    }

    this.setState(newState);
  }

  getStyleFromState(state) {
    let result = {};

    for (let key in state) {
      // Don’t add if not supported, or value is 0
      if (supportedProperties[key] && state[key] !== 0) {
        if (supportedProperties[key] && supportedProperties[key].units) {
          // Add to result with units
          result[key] = `${state[key]}${supportedProperties[key].units}`;
        } else {
          // Add to result
          result[key] = state[key];
        }
      }
    }

    // Remove fontSize if we are auto-scaling
    // TODO DO this for auto-lineHeight as well
    if (state.scale) {
      delete result["fontSize"];
    }

    return result;
  }

  getKeyPrefix(props) {
    props = props || this.props;
    if (!props || !props.value) {
      return "";
    }

    return props.value.substring(0, 10);
  }

  createControl(controlObj) {
    let obj = Object.assign({}, controlObj);
    const { Input, Slider, Select, Option } = this.components;
    const keyPrefix = `${obj.type}_${this.keyPrefix}`;

    if (obj.component) {
      let CustomInput = obj.component;
      return <CustomInput {...obj} />;
    }

    switch (obj.type) {
      case "select":
        let options = obj.options;
        delete obj.defaultValue;

        return (
          <Select {...obj} key={keyPrefix}>
            {options
              ? options.map((opt, index) => {
                  if (typeof opt === "string") {
                    opt = { value: opt, label: opt };
                  }
                  // TODO This key isn’t good enough, easy to
                  //      be the same if there are many testers
                  //      on a single page.
                  let optKey = `${keyPrefix}_${opt.value}_${index}`;
                  // console.log("optKey", optKey);
                  return (
                    <Option key={optKey} value={opt.value}>
                      {opt.label}
                    </Option>
                  );
                })
              : null}
          </Select>
        );
      case "checkbox":
        return <Input {...obj} key={keyPrefix} />;
      case "range":
        return <Slider {...obj} key={keyPrefix} />;
      default:
        delete obj.defaultValue;
        return <Input {...obj} key={keyPrefix} />;
    }
  }

  getControlsFromState(state) {
    const controlConfig = this.controlConfig;
    const Label = this.components.Label;
    let rows = [];

    controlConfig.forEach((controlRow, i) => {
      if (!Array.isArray(controlRow) && typeof controlRow === "object") {
        let controls = [];
        let rowKey = `ControlRow_${this.keyPrefix}_${i}`;

        Object.keys(controlRow).forEach((key, j) => {
          // How far does this go, until I’m re-creating JSON form schema?
          let obj = controlRow[key];
          let keyStr = `Control_${this.keyPrefix}_${key}_${j}`;
          let value = state[key];
          obj.key = keyStr;
          obj.onChange = this.handleOnChange;
          obj.name = key;
          obj.value = value;

          if (obj.type === "checkbox" || obj.type === "radio") {
            if (typeof obj.value !== "undefined") {
              obj.checked = obj.value;
            }
            if (typeof obj.defaultValue !== "undefined") {
              obj.defaultChecked = obj.deafultValue;
            }
          }

          controls.push(
            <Label
              key={`Label_${this.keyPrefix}_${j}`}
              text={obj.label}
              value={obj.value}
              className={`ControlledTypeTester__${obj.type}`}
            >
              {this.createControl(obj)}
            </Label>
          );
        });

        rows.push(<ToolbarRow key={rowKey}>{controls}</ToolbarRow>);
      }
    });

    return rows;
  }

  render() {
    const props = this.props;
    const state = this.state;
    const Container = this.components.Container;
    const remainingProps = Object.assign({}, props);
    delete remainingProps.controls;
    delete remainingProps.scale;
    let controls = this.getControlsFromState(state);
    let style = this.getStyleFromState(state);

    const children = props.children ? (
      props.children
    ) : (
      <TypeTester
        {...remainingProps}
        scale={state.scale}
        scaleOnEdit={props.scaleOnEditAndLineBreak}
        scaleOnLineBreak={props.scaleOnEditAndLineBreak}
        externallyControlled={!state.scale}
        onChangeFontSize={(fontSize, detail) => {
          let newFontSize = Math.ceil(parseInt(fontSize, 10) * 0.95);
          this.setState({
            fontSize: newFontSize,
          });
          if (
            typeof props.onChangeFontSize !== "undefined" &&
            typeof props.onChangeFontSize === "function"
          ) {
            props.onChangeFontSize();
          }
        }}
      />
    );

    return (
      <div className="ControlledTypeTester">
        <ToolbarContainer>{controls}</ToolbarContainer>
        <Container style={style}>
          {/* Need a wrapper div for flexbox */}
          <div>{children}</div>
        </Container>
      </div>
    );
  }
}

ControlledTypeTesterCustomized.defaultProps = {
  scale: true,
  components: defaultComponents,
  controls: [supportedProperties],
  minFontSize: 20,
  maxFontSize: 200,
  defaultLineHeight: 1.2,

  // These have to be tied together in this mode now?
  scaleOnEditAndLineBreak: false
};

ControlledTypeTesterCustomized.propTypes = {
  onChangeBeforeSetState: PropTypes.func,
  controls: PropTypes.arrayOf(PropTypes.object),
};

export default ControlledTypeTesterCustomized;
