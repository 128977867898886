import React from "react";
import { useTranslation } from "react-i18next";
import { LocaleContext } from "gatsby-theme-i18n";

// Ours
import Wrapper from "../components/Wrapper";

/* A truly comprehensive body text face, Andron’s sophisticated design and extensive character coverage are suitable for even the most ambitious editions. */

const Header = (props) => {
  const { t } = useTranslation();
  const locale = React.useContext(LocaleContext);

  return (
    <header className="Header" id="js-header">
      <Wrapper>
        <div className="Header__image-holder">
          <img
            className="Header__image"
            src={`/images/${locale}/header.jpg`}
            alt={t("headerImageAlt")}
          />
        </div>
      </Wrapper>
    </header>
  );
};

export default Header;
