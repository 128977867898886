import BioBlurb from "../../../../src/components/BioBlurb";
import IntroSample1 from "../../../../src/components/IntroSample1";
import IntroSample2 from "../../../../src/sections/1_intro/IntroSample2";
import IntroSample3 from "../../../../src/sections/1_intro/IntroSample3";
import TestimonialCycle from "../../../../src/components/TestimonialCycle";
import * as React from 'react';
export default {
  BioBlurb,
  IntroSample1,
  IntroSample2,
  IntroSample3,
  TestimonialCycle,
  React
};