import React from "react";
import { TypeTester } from "typor";
import AndronTester from "../../components/AndronTester";

const IntroSample2 = (props) => {
  const controls = [
    {
      fontFamily: {
        label: "Typeface",
        type: "select",
        defaultValue: "Andron Mega Corpus Regular",
        options: [
          {
            label: "Andron Regular",
            value: "Andron Mega Corpus Regular",
          },
          {
            label: "Andron Italic",
            value: "Andron Mega Corpus Italic",
          },
          {
            label: "Andron Bold",
            value: "Andron Mega Corpus Bold",
          },
          {
            label: "Andron Bold Italic",
            value: "Andron Mega Corpus Bold Italic",
          },
          // {
          //   label: "Andron Small Caps Regular",
          //   value: "Andron Mega Corpus SC Regular",
          // },
          // {
          //   label: "Andron Small Caps Italic",
          //   value: "Andron Mega Corpus SC Italic",
          // },
        ],
      },
      // fontSize: {
      //   label: "Font Size",
      //   type: "range",
      //   defaultValue: 50,
      //   min: 4,
      //   max: 400,
      //   units: "px",
      // },
      // lineHeight: {
      //   label: "Line Height",
      //   type: "range",
      //   defaultValue: 1.2,
      //   min: 1,
      //   max: 2,
      //   step: 0.1,
      // },
      // smcp: {
      //   type: "checkbox",
      //   label: "Small Caps",
      // },
      italic: {
        type: "checkbox",
        label: "Italics",
      },
    },
  ];

  const phrases = [
    `Metropolitan Railways`,
    `Wolfgang Amadeus Mozart`,
    `HAMBURG–BARCELONA`,
    `Ειδήσεις Θεσσαλονίκης`,
    `SYMPHONIEKONZERTABEND`,
    `Pafnuty Παπνούθιος Πⲁⲡⲛⲟⲩⲧⲉ Пафнутий`,
    `международный аэропорт`,
    `⚒ ⚖ ♿ ❦ ⚕ ❄ ☞ ☠ ⚓ ♈ ♉ ♊ ♋ ♌ ☾ ♂ ♃`,
    `Αθλητικά ϗ Ομάδες`,
    `Một bức thư pháp được`,
    `Agbetɔwo pleŋu vanɔ gbɛmɛ ko vovoɖeka`,
    `Ⲁⲃⲣⲁⲁⲙ ⲇⲉ ⲁϥ⳿ϫⲫⲉ⳿ Ⲓⲥⲁⲁⲕ`,
    `𐍂𐌴𐌹𐌺𐌹 𐍃𐍅𐌴𐌱𐌴 𐌲𐌰𐌻𐌹𐍃𐌾𐍉`,
    `Alle mensen worden vrĳ en gelĳk in waardigheid `,
    `en rechten geboren. Zĳ zĳn begiftigd met verstand`,
    `ᚦᚱᛦᚱᛁᚴᛋᛏᚢᛏᛁᚱ᛬ᛁᚠᛐᛁᚱᚮᛋᚱᛁᚦᛁ`,
    `Håkon M. Jørgenssen ⅍ `,
    `€12 £34 $56 ¥78 ¢90 & § ¶ @ ℅`,
    `ANCHORAGE`,
    `Inuit tamarmik inunngorput nammineersinnaassuseqarlutik `,
    `assigiimmillu ataqqinassuseqarlutillu pisinnaatitaaffeqarlutik.`,
    `Københavns Rådhus`,
    `The merchant of Venice`,
    `by William Shakespeare`,
    `Домодедово`,
    `NORSKE SIGILLER FRA MIDDELALDEREN`,
    `Она су обдарена разумом и свијешђу и треба да једно према другоме поступају у духу братства.`,
  ];

  return (
    <div>
      <AndronTester controls={controls}>
        <TypeTester
          textAlignment="left"
          scale
          scaleOnLineBreak
          minFontSize={16}
          maxFontSize={500}
          value={phrases.join(`\n`)}
        />
      </AndronTester>
    </div>
  );
};

export default IntroSample2;
