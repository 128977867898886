import React from "react";
import ReactDOM from "react-dom";
import opentype from "opentype.js";
import unicodeRange from "unicode-range";
import Fuse from "fuse.js";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";

const Glyph = (props) => {
  // &nbsp;
  let char = props.character || " ";

  return (
    <li
      className="g"
      id={typeof props.id !== "undefined" ? props.id : `${props.code}`}
    >
      <div className="g__sample">{char}</div>
      <div className="g__meta">
        <a href={`#${props.code}`} className="g__name">
          {props.name}
        </a>
        <div className="g__code">{props.code}</div>
      </div>
    </li>
  );
};

const GlyphListing = (props) => {
  let items = [];

  /*

  {
    "index": 10,
    "name": "parenleft",
    "unicode": 40,
    "unicodes": [
      40
    ],
    "xMin": 40,
    "xMax": 299,
    "yMin": -242,
    "yMax": 715,
    "advanceWidth": 320,
    "leftSideBearing": 40
  }

  */

  for (const index in props.glyphs) {
    const g = props.glyphs[index];
    items.push(<Glyph key={index} {...g} />);
  }

  return <ul className="g__listing">{items}</ul>;
};

const GlyphGroup = (props) => {
  let output = (
    <figure>
      <GlyphListing glyphs={props.glyphs} />
    </figure>
  );

  if (!props.portalEl) {
    return output;
  }

  return ReactDOM.createPortal(output, props.portalEl);
};

const SectionCharSearchResultsHeader = (props) => {
  const { t } = useTranslation();

  return <h3>{t("Search Results")}</h3>;
};

const SectionCharSearchResultsMessage = ({ searchTerm, count }) => {
  const { t } = useTranslation();
  let resultsSuffix = " ";

  if (count === '1') {
    resultsSuffix = "result";
  } else {
    resultsSuffix = "results";
  }

  return (
    <div>
      {count} {t(resultsSuffix)}
    </div>
  );
};

class SectionCharSearch extends React.Component {
  constructor() {
    super();

    this.state = {
      status: "loading",
      searchTerm: "",
      searchResults: [],
    };

    this.fuse = null;
    this.font = null;

    // Flat array of glyphs
    this.glyphs = [];

    // Glyphs sorted into their Unicode Group objects
    this.glyphsByGroup = {};

    // Object keys of glyphsByGroup
    this.glyphsByGroupKeys = [];

    this.initFuse = this.initFuse.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleUpdateSearch = debounce(this.handleUpdateSearch.bind(this), 300);
  }

  // Huge hack to get around remark+nbsp and MDX issue, find
  // el based on aria-label instead of slug, and work around
  // the same inconsistency as in getSlug()
  getHeadingEl(group) {
    let groupLabel = group
      .toLowerCase()
      .split("-")
      .join(" ")
      .split("(")
      .join("")
      .split(")")
      .join("");
    let headingEl = null;
    let el = document.querySelector(`a[aria-label="${groupLabel} permalink"]`);

    if (!el) {
      let lastSpace = groupLabel.lastIndexOf(" ");
      groupLabel =
        groupLabel.slice(0, lastSpace) + groupLabel.slice(lastSpace + 1);
      el = document.querySelector(`a[aria-label="${groupLabel} permalink"]`);
    }

    if (!el) {
      let groupLabel = group.toLowerCase();
      let lastSpace = groupLabel.lastIndexOf(" ");
      groupLabel =
        groupLabel.slice(0, lastSpace) + groupLabel.slice(lastSpace + 1);
      groupLabel = groupLabel.split("-").join(" ");
      el = document.querySelector(`a[aria-label="${groupLabel} permalink"]`);
    }

    if (el) {
      headingEl = el.parentNode;
    }

    return headingEl;
  }

  componentDidMount() {
    if (this.font && this.font.supported) {
      return;
    }

    const path = `/fonts/andron-sort.otf`;
    const buffer = fetch(path).then((res) => res.arrayBuffer());

    buffer.then((data) => {
      const font = opentype.parse(data);

      if (font.supported) {
        this.font = font;

        const glyphs = this.font.glyphs.glyphs;

        Object.keys(glyphs).forEach((gIndex) => {
          let glyphObj = glyphs[gIndex];
          glyphObj.code = null;

          if (glyphObj.unicode) {
            glyphObj.character = String.fromCodePoint(glyphObj.unicode);

            let code = glyphObj.unicode.toString(16);
            code = code.padStart(4, "0");
            glyphObj.code = `U+${code.toUpperCase()}`;

            let group = unicodeRange(code);
            glyphObj.group = group;

            if (group === "Control Character") {
              return null;
            }

            if (!this.glyphsByGroup[group]) {
              let headingEl = this.getHeadingEl(group);

              let groupObj = {
                name: group,
                glyphs: [],
                portalEl: null,
              };

              if (headingEl) {
                let portalEl = document.createElement("div");
                let parent = headingEl.parentNode;
                portalEl.id = `portal-${headingEl.id}`;
                groupObj.portalEl = portalEl;

                parent.insertBefore(portalEl, headingEl.nextSibling);
              } else {
                console.warn("Missing heading", group);
              }

              this.glyphsByGroup[group] = groupObj;
            }

            this.glyphs.push(glyphObj);
            this.glyphsByGroup[group].glyphs.push(glyphObj);

            return glyphObj;
          }
        });

        this.glyphsByGroupKeys = Object.keys(this.glyphsByGroup);

        this.setState(
          {
            status: "loaded",
          },
          () => {
            this.reInitHashUrl()
            this.initFuse()
          }
        );
      } else {
        this.setState({
          status: "error",
        });
      }
    });
  }

  initFuse() {
    const options = {
      includeScore: true,
      threshold: 0.05, // 0 is a perfect match
      keys: ["character", "name", "group", "code", "unicode"],
    };

    const fuse = new Fuse(this.glyphs, options);

    this.fuse = fuse;
  }

  /**
   * Check the hash in the URL again, now that the Unicode table is loaded.
   */
  reInitHashUrl() {
    if (typeof window !== 'undefined' && window.location.hash) {
      let hash = window.location.hash.toString()
      window.location.hash = ''
      window.setTimeout(() => {
        window.location.hash = hash        
      }, 1)
    }
  }

  handleOnChange(e) {
    const searchTerm = e.target.value;

    this.setState(
      {
        searchTerm: searchTerm,
      },
      this.handleUpdateSearch
    );
  }

  handleUpdateSearch() {
    const searchResults = this.fuse.search(this.state.searchTerm);

    this.setState({
      searchResults: searchResults,
    });
  }

  render() {
    const state = this.state;
    const searchTerm = state.searchTerm;
    const searchResults = state.searchResults;

    if (state.status === "loading") {
      return "Loading…";
    }

    if (state.status === "error") {
      return null;
    }

    return (
      <figure>
        <div>Try “Aring” or “Ꙛ” or “U+03A9”</div>
        <input
          value={searchTerm}
          type="text"
          className="input"
          onChange={this.handleOnChange}
        />

        <div>
          <SectionCharSearchResultsHeader />
          <SectionCharSearchResultsMessage
            searchTerm={searchTerm}
            count={searchResults.length.toString()}
          />
          <ul className="g__listing">
            {searchResults.splice(0, 100).map((resultObj, resultIndex) => {
              return (
                <Glyph
                  key={`result_${resultIndex}`}
                  {...resultObj.item}
                  id=""
                />
              );
            })}
          </ul>
        </div>

        {this.glyphsByGroupKeys.map((groupKey) => {
          let groupObj = this.glyphsByGroup[groupKey];

          return <GlyphGroup key={`Group_${groupKey}`} {...groupObj} />;
        })}
      </figure>
    );
  }
}

export default SectionCharSearch;
