import React, { useEffect, useState, useRef } from "react";
import Testimonial from "./Testimonial";
import { LocaleContext } from "gatsby-theme-i18n";

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const TestimonialCycle = ({ testimonials, delay }) => {
  const [testimonialIndex, setTestimonialIndex] = useState(0);

  useInterval(() => {
    let nextIndex = parseInt(testimonialIndex, 10) + 1;

    setTestimonialIndex(nextIndex >= testimonials.length ? 0 : nextIndex);
  }, delay);

  const testimonial = testimonials[testimonialIndex];
  const locale = React.useContext(LocaleContext);

  return (
    <div className="TestimonialCycle">
      <div className="TestimonialCycle__container">
        <Testimonial
          name={testimonial.name}
          org={testimonial.org[locale]}
          locale={locale}
        >
          {testimonial.quote[locale]}
        </Testimonial>
      </div>
    </div>
  );
};

TestimonialCycle.defaultProps = {
  delay: 5000,
  testimonials: [
    {
      quote: {
        en: "One of the best text fonts ever made",
        de: "eine der besten Schriften aller Zeiten",
      },
      name: "Igor Freiberger",
      org: { en: "Porto Alegre, Brazil", de: "Porto Alegre, Brasilien" },
    },
    {
      quote: {
        en: "There’s truly nothing left to be desired",
        de: "… läßt wahrlich nichts zu wünschen übrig",
      },
      name: "Herbert Wäckerlin",
      org: { en: "Zurich University, Switzerland", de: "Universität Zürich" },
    },
    {
      quote: {
        en: "Absolutely fantastic … solves a multitude of problems by its abundance of symbols … very sophisticated from a typographic point of view",
        de: "absolut phantastisch … löst durch ihren Zeichenreichtum eine Vielzahl von Problemen … in typographischer Hinsicht sehr anspruchsvoll",
      },
      name: "Prof. Haraldur Bernharðsson",
      org: {
        en: "The Árni Magnússon Institute for Icelandic Studies, Reykjavík",
        de: "Árni Magnússon Institut für Islandstudien, Reykjavík",
      },
    },
    {
      quote: {
        en: "We are very pleased with the possibilities the font gives us in our work",
        de: "Wir sind hocherfreut über die Möglichkeiten, die uns die Schrift bei unserer Arbeit gibt",
      },
      name: "Mette G. Ekker",
      org: {
        en: "Norwegian State Archive, Oslo",
        de: "Norwegisches Staatsarchiv, Oslo",
      },
    },
    {
      quote: {
        en: "Particularly well equipped for scientific text composing",
        de: "besonders gut für wissenschaftlichen Werksatz ausgebaut",
      },
      name: "Prof. Johannes Bergerhausen",
      org: { en: "Fachhochschule Mainz, Germany", de: "Fachhochschule Mainz" },
    },
    {
      quote: {
        en: "Andron’s character inventory is just perfect",
        de: "Das Zeicheninventar der Andron ist perfekt",
      },
      name: "Prof. Gerhard Endress",
      org: { en: "Bochum University, Germany", de: "Universität Bochum" },
    },
    {
      quote: {
        en: "quite the most beautiful and quite the most literate font that there is",
        de: "die schönste und vielseitigste Schrift die es gibt",
      },
      name: "Karl Medlicott",
      org: { en: "Vancouver, Canada", de: "Vancouver, Kanada" },
    },
    {
      quote: {
        en: "Andron acts always effortlessly superior",
        de: "Die Andron wirkt überall souverän",
      },
      name: "",
      org: { en: "typografie.info", de: "typografie.info" },
    },
    {
      quote: {
        en: "The strength of Andron – that it is being updated by a very competent type designer & researcher.",
        de: "Die Stärke der Andron ist, daß sie von einem sehr kompetenten Schriftgestalter und Forscher gepflegt wird.",
      },
      name: "Prof. Odd Einar Haugen",
      org: {
        en: "Bergen University, Norway",
        de: "Universität Bergen, Norwegen",
      },
    },
    {
      quote: {
        en: "… very elegant",
        de: "… sehr elegant",
      },
      name: "Antonio Costanzo",
      org: {
        en: "Reykjavík, Iceland",
        de: "Reykjavík, Island",
      },
    },
    {
      quote: {
        en: "I am honored to be part of a project so integral to the migrations of texts online with such beauty and accuracy.",
        de: "Ich empfinde es als Ehre an einem Projekt teilzuhaben, das so wesentlich für die Übertragung von Texten ins Netz ist, mit solcher Schönheit und Sorgfalt.",
      },
      name: "Prof. Joel W. Fredell",
      org: {
        en: "Southeastern Louisiana University, Hammond (LA)",
        de: "Southeastern Louisiana University, Hammond (LA)",
      },
    },
    {
      quote: {
        en: "excellent work",
        de: "ausgezeichnete Arbeit",
      },
      name: "Dewi Evans",
      org: {
        en: "University College Dublin, Ireland",
        de: "University College Dublin, Irland",
      },
    },
    {
      quote: {
        en: "I love the type and your meticulous work",
        de: "Ich liebe die Schrift und Ihre akkurate Arbeit",
      },
      name: "Michael Gordon",
      org: {
        en: "Tel Aviv, Israel",
        de: "Tel Aviv, Israel",
      },
    },
  ],
};

export default TestimonialCycle;
