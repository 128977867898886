import React from "react";
import PropTypes from "prop-types";

const Testimonial = (props) => {
  const long = typeof props.children === "string" && props.children.length > 75;

  return (
    <blockquote className="Testimonial">
      <div className="Testimonial__quote-container">
        <q
          className={`Testimonial__quote ${
            long ? "Testimonial__quote--long" : ""
          }`}
        >
          {props.children}
        </q>
      </div>
      <footer className="Testimonial__citation">
        {props.name ? (
          <div className="Testimonial__citation-name">{props.name}</div>
        ) : null}
        {props.org ? (
          <div className="Testimonial__citation-org">{props.org}</div>
        ) : null}
      </footer>
    </blockquote>
  );
};

Testimonial.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  org: PropTypes.string,
};

export default Testimonial;
