import React from "react";
import AndronTester from "./AndronTester";

const controlsConfig = [
  {
    fontFamily: {
      label: "Typeface",
      type: "select",
      defaultValue: "Andron Mega Corpus Regular",
      options: [
        {
          label: "Andron Regular",
          value: "Andron Mega Corpus Regular",
        },
        {
          label: "Andron Italic",
          value: "Andron Mega Corpus Italic",
        },
      ],
    },
    fontSize: {
      label: "Font Size",
      type: "range",
      defaultValue: 50,
      min: 9,
      max: 100,
      units: "px",
    },
    lineHeight: {
      label: "Line Height",
      type: "range",
      defaultValue: 1.3,
      min: 1,
      max: 2,
      step: 0.1,
    },
    italic: {
      type: "checkbox",
      label: "Italics",
    },
  },
];

class PageScriptsDemo7 extends React.Component {
  render() {
    let value = this.props.value;
    if (Array.isArray(value)) {
      value = value.join("\n");
    }

    if (this.props.fontSize) {
      controlsConfig[0].fontSize.defaultValue = this.props.fontSize
    }

    return (
      <div className="PageScriptsDemo7">
        <AndronTester
          textAlignment={this.props.textAlignment}
          controls={controlsConfig}
          scale={this.props.fontSize ? false : this.props.scale}
          value={value}
        />
      </div>
    );
  }
}

PageScriptsDemo7.deafultProps = {
  scale: true,
  textAlignment: 'center'
}

export default PageScriptsDemo7;
