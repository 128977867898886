import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const BioBlurb = (props) => {
  return (
    <div>
      <div className="BioBlurb">
        <div className="BioBlurb__container">
          <div className="BioBlurb__container-col BioBlurb__image">
            <StaticImage
              src="../images/profile.jpg"
              alt="Andreas Stötzner"
              width={600}
              height={600}
              transformOptions={{ fit: "cover", cropFocus: "center" }}
            />
          </div>
          <div className="BioBlurb__container-col BioBlurb__content">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BioBlurb;
