import React from 'react'

const Label = (props) => {
  return (
    <label className="Label">
      <div className="Label__text">{props.text}</div>
      <div className="Label__input">{props.children}</div>
    </label>
  );
};

export default Label
