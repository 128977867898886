import React from "react";
import { useTranslation } from "react-i18next";

const StyleListing = (props) => {
  const phrases = props.phrases;
  const styles = props.styles;
  const { t } = useTranslation();

  return (
    <table className="StyleListing">
      <tbody>
        {styles.map((item, index) => {
          const itemPhrases = item.phrases || phrases;
          const keyPrefix = `StyleListing_${index}`;

          return (
            <tr key={keyPrefix} className="StyleListing__item">
              <th className="StyleListing__item-label">{t(item.label)}</th>
              <td className="StyleListing__item-content">
                <div style={item.style}>
                  {itemPhrases.map((phrase, pIndex) => (
                    <div key={`${keyPrefix}_${pIndex}`}>{phrase}</div>
                  ))}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

StyleListing.defaultProps = {
  styles: [
    { label: "Regular" },
    { label: "Italic", style: { fontStyle: "italic" } },
    { label: "Semibold", style: { fontWeight: 600 } },
    {
      label: "Semibold Italic",
      style: { fontWeight: 600, fontStyle: "italic" },
    },
    {
      label: "Small Capitals",
      style: { fontFamily: "Andron Mega Corpus SC" },
    },
    {
      label: "Italic Small Caps",
      style: { fontFamily: "Andron Mega Corpus SC", fontStyle: "italic" },
    },
  ],
  phrases: [
    `centre of typographic excellence`,
    `κέντρο τυπογραφικής αριστείας`,
  ],
};

export default StyleListing;
