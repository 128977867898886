import React from "react";
import AndronTester from "../components/AndronTester";

const SectionTester = (props) => {
  return (
    <div className="SectionTester">
      <figure>
        <AndronTester {...props} />
        <figcaption>Text from <a href="https://de.wikipedia.org/wiki/Prespasee">Prespasee</a>.</figcaption>
      </figure>
    </div>
  );
};

export default SectionTester;
