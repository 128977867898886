import React from "react";
import AndronTester from "./AndronTester";

const IntroSample1 = ({
  defaultFontSize,
  defaultLineHeight,
  smallCaps,
  children,
  ...testerProps
}) => {
  let controls = JSON.parse(JSON.stringify(AndronTester.defaultProps.controls));

  // Keep nearly all the defaults
  controls.forEach((ctrl) => {
    if (defaultFontSize) {
      if (typeof ctrl["fontSize"] !== "undefined") {
        ctrl["fontSize"].defaultValue = defaultFontSize;
      }
    }

    if (defaultLineHeight) {
      if (typeof ctrl["fontSize"] !== "undefined") {
        ctrl["lineHeight"].defaultValue = defaultLineHeight;
      }
    }

    if (smallCaps === false) {
      if (typeof ctrl["smcp"] !== "undefined") {
        delete ctrl["smcp"];
      }

      if (typeof ctrl["fontFamily"] !== "undefined") {
        let res = [];
        ctrl["fontFamily"].options.forEach((opt) => {
          if (!opt.label.includes("Small Caps") && !opt.label.includes("Bold Italic")) {
            res.push(opt);
          }
        });

        ctrl["fontFamily"].options = res;
      }
    }
  });

  return (
    <div>
      <AndronTester
        scale={false}
        scaleOnEditAndLineBreak={false}
        textAlignment="left"
        {...testerProps}
        controls={controls}
        value={testerProps.value || children}
      />
    </div>
  );
};

IntroSample1.defaultProps = {
  
  smallCaps: false,
};

export default IntroSample1;
