import React from "react";

const Button = ({ is, outline, ...remainingProps }) => {
  const Tag = is;
  return <Tag className={`Button ${outline ? 'Button--outline' : ''}`} {...remainingProps} />;
};

Button.defaultProps = {
  is: "button",
  outline: false
};

export default Button;
