import React from 'react'

const classNameConfig = {
  'narrow': 'Wrapper Wrapper--narrow',
  'normal': 'Wrapper',
  'full': ''
}

const Wrapper = props => {
  let classes = classNameConfig['normal']
  if (typeof classNameConfig[props.width] !== 'undefined') {
    classes = classNameConfig[props.width]
  }

  return <div className={classes}>{props.children}</div>
}

Wrapper.defaultProps = {
  width: 'normal'
}

export default Wrapper
