import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CharacterGridItem = (props) => {
  const { t } = useTranslation();

  return (
    <div className="CharacterGrid__group">
      <h3 className="CharacterGrid__group-label">{t(props.label)}</h3>
      {props.description && props.description !== "" ? (
        <div className="CharacterGrid__group-description">
          {props.description}
        </div>
      ) : null}
      <ol className="CharacterGrid__items">
        {props.items.map((item, index) => {
          const keyStr = `${props.keyPrefix}_${item}_${index}`;

          return (
            <li
              key={keyStr}
              className={`CharacterGrid__item ${
                props.selectedGroupIndex === props.groupIndex &&
                props.selectedGlyphIndex === index
                  ? "CharacterGrid__item--selected"
                  : ""
              }`}
            >
              <label className="CharacterGrid__char">
                {item}
                <input
                  className="hide"
                  type="radio"
                  name="CharacterGrid"
                  data-group-index={props.groupIndex}
                  data-glyph-index={index}
                  onChange={props.onClick}
                />
              </label>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

CharacterGridItem.propTypes = {
  keyPrefix: PropTypes.string.isRequired,
};

export default CharacterGridItem;
