import React from "react";
import AndronTester from "../components/AndronTester";

class PageScriptsDemo2 extends React.Component {
  render() {
    const lines = [
      `Ἄνδρα μοι ἔννεπε, Μοῦσα, πολύτροπον, ὃς μάλα πολλὰ`,
      `πλάγχθη, ἐπεὶ Τροίης ἱερὸν πτολίεθρον ἔπερσε·`,
      `πολλῶν δ’ ἀνθρώπων ἴδεν ἄστεα καὶ νόον ἔγνω,`,
      `πολλὰ δ’ ὅ γ’ ἐν πόντῳ πάθεν ἄλγεα ὃν κατὰ θυμόν, `,
      `When to the sessions of sweet silent thought`,
      `I summon up remembrance of things past,`,
      `I sigh the lack of many a thing I sought,`,
      `And with old woes new wail my dear time's waste:`,
      `Я памятник себе воздвиг нерукотворный,`,
      `К нему не заростет народная тропа,`,
      `Вознёсся выше он главою непокорной`,
      `Александрийского столпа.`,
    ];

    return (
      <AndronTester
        scale={false}
        controls={[
          {
            fontFamily: {
              label: "Typeface",
              type: "select",
              defaultValue: "Andron Mega Corpus Regular",
              options: [
                {
                  label: "Andron Regular",
                  value: "Andron Mega Corpus Regular",
                },
                {
                  label: "Andron Italic",
                  value: "Andron Mega Corpus Italic",
                },
                {
                  label: "Andron Bold",
                  value: "Andron Mega Corpus Bold",
                },
                {
                  label: "Andron Bold Italic",
                  value: "Andron Mega Corpus Bold Italic",
                },
                {
                  label: "Andron Small Caps Regular",
                  value: "Andron Mega Corpus SC Regular",
                },
                {
                  label: "Andron Small Caps Italic",
                  value: "Andron Mega Corpus SC Italic",
                },
              ],
            },
            fontSize: {
              label: "Font Size",
              type: "range",
              defaultValue: 28,
              min: 9,
              max: 48,
              units: "px",
            },
            lineHeight: {
              label: "Line Height",
              type: "range",
              defaultValue: 1.5,
              min: 1,
              max: 2,
              step: 0.1,
            },
            smcp: {
              type: "checkbox",
              label: "Small Caps",
            },
            italic: {
              type: "checkbox",
              label: "Italics",
            },
          },
        ]}
      >
        {lines.map((item, index) => {
          const keyStr = `PageScriptDemo2_${index}`;
          return <div key={keyStr}>{item}</div>;
        })}
      </AndronTester>
    );
  }
}

export default PageScriptsDemo2;
