import React from "react";
import AndronTester from "../components/AndronTester";

const controlsConfig = [
  {
    fontFamily: {
      label: "Typeface",
      type: "select",
      defaultValue: "Andron Mega Corpus Regular",
      options: [
        {
          label: "Andron Regular",
          value: "Andron Mega Corpus Regular",
        },
        // {
        //   label: "Andron Bold",
        //   value: "Andron Mega Corpus Bold",
        // },
        // {
        //   label: "Andron Italic",
        //   value: "Andron Mega Corpus Italic",
        // },
        // {
        //   label: "Andron Bold Italic",
        //   value: "Andron Mega Corpus Bold Italic",
        // },
        // {
        //   label: "Andron Small Caps Regular",
        //   value: "Andron Mega Corpus SC Regular",
        // },
        // {
        //   label: "Andron Small Caps Italic",
        //   value: "Andron Mega Corpus SC Italic",
        // },
      ],
    },
    fontSize: {
      label: "Font Size",
      type: "range",
      defaultValue: 28,
      min: 9,
      max: 48,
      units: "px",
    },
    lineHeight: {
      label: "Line Height",
      type: "range",
      defaultValue: 1.6,
      min: 1,
      max: 2,
      step: 0.1,
    },
    // smcp: {
    //   type: "checkbox",
    //   label: "Small Caps",
    // },
    // italic: {
    //   type: "checkbox",
    //   label: "Italics",
    // },
  },
];

class PageScriptsDemo6 extends React.Component {
  render() {
    return (
      <div className="PageScriptsDemo6">
        <AndronTester
        textAlignment="left"
          scale={false}
          controls={controlsConfig}
          value={[
            "nŭ̮o̭·rʀt́ĺᴸ͕́t́ɛ e̮d́d́e̮͕̬n ńe̮re̮͕̬n ad́d́ž́ɛ̮͕ sud́ˢ̌́ž́ χ͔βȯ͔ˡ́ʟ́₍ᴦ‿γəsäχᵩ nəru͔̮̇̄i̯ɜ t͔́əlmèn  t͔́ələ₍ṭ̄ṣ̌ət u̯ă̭χtə̑ȱ͔̮i̯ɜ jīĺ̆ĺib̬a͕̭₍t̜́̀ˢ̜́i˒˒ χä̆è͔β̬˒˒ χ̬ō͕t̜̬́̄ś̜̬ χ̬u͕ń₍t̜̬́ś̜̬ē·rk̆ka͕rt̀ᵄ  ö͔ŋᵤkè·ĺ  ö͔ŋ₍ki̯e·ĺ  ä̀˳ŋ₍kᵦl klož́ìńt́̆t́ə̑",
            "knaš́̀šə̑t‿ puškùd˽ēdrikšə̑bə̑t‿taràs̀ pᴴridᵉ̇ku hu̮lᵘ̮kɛmɛ̮ ful̆ᴴkˎij̆ᴛ fŭ̮ö·lʟ˲kiɛ ᴋorᵕ̈ᴄ̌́iɛ kor̆ʜ̄č́iɛ  mə̑·škam  mᴑ·škam  pᴞnᵈzᴞ  pᴞ·ńᵈzᴞ pü·nᴅᴢo̤ pᴞ·ń₍ᴅ́ᴢ̌́ö̬  pü·ńdž́ə͐ ŋa̭ł̀ŋōδà͕  a̭łŋɐδa͖ś̜  łānōrŋɐᵊ̑  jɯ͔rɔ₍kʙtā͕š̜̬́ d̜́ᴟᴧɔknuŋ tōŋŋāt̀ā͕ś̜",
          ].join("\n\n")}
        />
      </div>
    );
  }
}

export default PageScriptsDemo6;
