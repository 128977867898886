import React from "react";
import AndronTester from "../components/AndronTester";
import { useTranslation } from "react-i18next";

const controlsConfig = [
  {
    fontFamily: {
      label: "Typeface",
      type: "select",
      defaultValue: "Andron Mega Corpus Regular",
      options: [
        {
          label: "Andron Regular",
          value: "Andron Mega Corpus Regular",
        },
        {
          label: "Andron Italic",
          value: "Andron Mega Corpus Italic",
        },
        {
          label: "Andron Bold",
          value: "Andron Mega Corpus Bold",
        },
        // {
        //   label: "Andron Bold Italic",
        //   value: "Andron Mega Corpus Bold Italic",
        // },
        // {
        //   label: "Andron Small Caps Regular",
        //   value: "Andron Mega Corpus SC Regular",
        // },
        // {
        //   label: "Andron Small Caps Italic",
        //   value: "Andron Mega Corpus SC Italic",
        // },
      ],
    },
    fontSize: {
      label: "Font Size",
      type: "range",
      defaultValue: 36,
      min: 9,
      max: 200,
      units: "px",
    },
    lineHeight: {
      label: "Line Height",
      type: "range",
      defaultValue: 1.5,
      min: 1,
      max: 2,
      step: 0.1,
    },

    // smcp: {
    //   type: "checkbox",
    //   label: "Small Caps",
    // },

    italic: {
      type: "checkbox",
      label: "Italics",
    },
  },
];

const PageScriptsDemo3 = (props) => {
  const { t } = useTranslation();

  const groups = [
    {
      label: 'Greek (selected)',
      items: [
        `ͱͰ ͳͲ ͷͶ ϙϘ ϝϜ ϟϞ ϡϠ Ϲ Ϻ Ͽ ϼ`,
        `𐅄 𐅆 𐅈 𐅉 𐅊 𐅌 𐅎 𐅏 𐅐 𐅓 𐅕 𐅗 𐅙`,
        `𐅜 𐅝 𐅟 𐅡 𐅢 𐅣 𐅥 𐅦 𐅪 𐅫 𐅮 𐅰`,
        ` `,
      ],
    },
    {
      label: 'Coptic (selected)',
      items: [`Ⲷ Ⲿ Ⳃ Ⳅ Ⳉ Ⳛ Ⳡ Ⳗ`],
      options: [
        {
          label: "Andron Regular",
          value: "Andron Mega Corpus Regular",
        },
      ],
    },
    {
      label: 'Latin (selected)',
      items: [
        `  Ʀ ƿǷ ꝣꝢ ꝡꝠ ꝧꝦ ꝩꝨ ꝑꝐ ꝓꝒ ꝗꝖ ꝛꝚ ꝝ ꟿ`,
        `                            `,
        `                      `,
        `Ꜳ Ꜹ          `,
        `     ꝯ ꝰ ꝱ ꝵ ꝸ ꞇ     `,
        `ↀ ↁ ↂ ↅ ↆ ↇ ↈ`,
      ],
    },
    {
      label: 'Cyrillic (selected)',
      items: [
        `ѣѢ ѩѨ ѫѪ ѯѮ ѷѶ ѽѼ ҍҌ ҧҦ ҩҨ ұҰ ҹҸ ҿҾ`,
        `ӫӪ ӻӺ ӿӾ ԅԄ ԋԊ ԙԘ ԟԞ ԡԠ ꙓꙒ ꙗꙖ ꙛꙚ`,
        `ꙭ ꙮ ꙳`,
      ],
      options: [
        {
          label: "Andron Regular",
          value: "Andron Mega Corpus Regular",
        },
        {
          label: "Andron Bold",
          value: "Andron Mega Corpus Bold",
        },
      ]
    },
  ];

  return (
    <div>
      {groups.map((group, index) => {
        const keyStr = `PageScriptDemo3_${index}`;
        let controls = controlsConfig

        if (typeof group.options !== 'undefined') {
          controls = JSON.parse(JSON.stringify(controlsConfig))
          controls[0].fontFamily.options = group.options

          // Delete italic, not really based on the extra config,
          // but that’s why we are overriding the config
          delete controls[0].italic
        }

        return (
          <div key={keyStr}>
            <h3>{t(group.label)}</h3>
            <div>
              <figure>
                <AndronTester
                  scale={false}
                  textAlignment="center"
                  controls={controls}
                  value={group.items.join("\n")}
                ></AndronTester>
              </figure>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PageScriptsDemo3;
