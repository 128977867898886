import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Headroom from "react-headroom";
import debounce from "lodash.debounce";

import Header from "./Header";
import PrimaryNav from "./PrimaryNav";
import Footer from "./Footer";

const getPath = function ({ location, pageContext }) {
  let path = typeof location !== "undefined" ? location.pathname : "/";
  path = pageContext ? pageContext.originalPath : path;

  return path;
};

// Seems like a bug, should be able to do this within Typor after scale and after
// all fonts are loaded, right?
const clearTyporFocus = function () {
  setTimeout(() => {
    if (
      document.activeElement &&
      typeof document.activeElement.blur === "function" &&
      document.activeElement.classList.contains("public-DraftEditor-content")
    ) {
      document.activeElement.blur();
    }
  }, 200);
};

const getMatchesMobile = function () {
  if (typeof window === "undefined") {
    return false;
  }

  return window.matchMedia("(max-width: 63.99em)");
};

const getHeaderHeight = function () {
  if (typeof window === "undefined") {
    return 0;
  }

  let el = document.getElementById("js-header");
  if (!el) {
    return 0;
  }

  let height = parseFloat(
    window.getComputedStyle(el).getPropertyValue("height"),
    10
  );

  return height;
};

const GlobalLayout = (props) => {
  const path = getPath(props);
  const homepage = path === "/";

  const [headerHeight, setHeaderHeight] = useState(0);

  // Clear focus on load, in case it’s stuck within a Type Tester from automatically scaling
  useEffect(() => {
    clearTyporFocus();
    if (getMatchesMobile()) {
      setHeaderHeight(getHeaderHeight());
    }

    if (window) {
      let handleResize = function () {
        if (getMatchesMobile()) {
          setHeaderHeight(getHeaderHeight());
        }
      };

      handleResize = debounce(handleResize, 250);

      window.addEventListener("resize", handleResize);

      // Unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          siteUrl
          author
        }
      }
    }
  `);

  return (
    <div>
      {homepage ? <Header /> : null}
      <Headroom
        disableInlineStyles
        pinStart={homepage ? headerHeight : 0}
        disable={!getMatchesMobile()}
      >
        <PrimaryNav
          currentPath={path}
          currentLocale={props.pageContext.locale}
        />
      </Headroom>
      {props.children}
      <Footer {...props} site={site} currentPath={path} />
    </div>
  );
};

GlobalLayout.defaultProps = {
  header: null,
};

export default GlobalLayout;
